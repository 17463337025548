import React, { useState, useEffect, useRef } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import Card from "../menu/Card"
import { useSelector, useDispatch } from "react-redux"
import { itemTypes } from "src/constants"
import { getItemType } from "src/utils"
import Box from "src/styled/Box"
import { fetchCarousel } from "src/redux/carousel"
import { getCarouselLocal } from "src/utils/localstorage"
import ScrollContainer from "react-indiana-drag-scroll"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import styled from "styled-components"
const CarouselSlide = styled.div`
    padding: 15px 0;
    width: 100%;
`
const StaticSlide = styled.div`
    width: 400px;
    flex-basis: 400px;
    padding: 5px;
    margin: 15px;
`
const MobileDiv = styled.div`
    padding-top: 20px;
    height: 250px;
    background-color: #f9f9f9;
`
const MobileH2 = styled.h2`
    padding-left: 20px;
    font-size: 18px;
`
const DesktopDiv = styled.div`
    overflow-x: hidden;
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 10px;
    margin: 50px auto;
    width: 75vw;
    padding: 5px 0;
`
const DesktopH1 = styled.h1`
    padding-left: 30px;
    margin-bottom: 20px;
    font-size: 28px;
`
const StaticCarouselWrapper = styled.div`
    display: flex;
    width: fit-content;
`
const ScrollableWrapper = styled.div`
    overflow-x: scroll;
    scrollbar-width: none;
    cursor: grab;
    &::-webkit-scrollbar {
        display: none;
    }
`
export default function() {
    SwiperCore.use([Autoplay])

    const dispatch = useDispatch()
    const [screenType, setScreenType] = useState("")
    const [cursor, setCursor] = useState({ isGrabbing: false })
    const { ingredients } = useSelector(state => state.menu)
    const { carousel } = useSelector(state => state.carousel)
    const wrapperRef = useRef(null)
    let carouselList
    useEffect(() => {
        if (window.innerWidth < 1080) {
            setScreenType("mobile")
        } else {
            setScreenType("desktop")
        }
    }, [])

    carouselList = carousel
    function getDescription(item) {
        const itemType = getItemType(item.id)
        const itemDescription = {
            [itemTypes.set]: getSetDescription,
            [itemTypes.product]: getProductDescription,
            [itemTypes.ingredient]: () => undefined,
        }
        return itemDescription[itemType](item)
    }

    function getSetDescription(set) {
        return set.products.map(
            product => `${product.name} x ${product.numberOfPieces}`
        )
    }

    function getProductDescription(product) {
        return product.ingredients.map(ingredient => `${ingredient.name}`)
    }
    const data = []

    const slidesToRender = carouselList.map((item, index) => (
        <SwiperSlide>
            <Card
                id={item.id}
                key={index}
                name={item.name}
                price={item.price}
                description={getDescription(item)}
                category={item.category}
                imageURL={item.imageURL}
            />
        </SwiperSlide>
    ))
    const staticSlides = carouselList.map((item, index) => (
        <SwiperSlide key={index}>
            <Card
                key={index}
                id={item.id}
                name={item.name}
                price={item.price}
                description={getDescription(item)}
                category={item.category}
                imageURL={item.imageURL}
            />
        </SwiperSlide>
    ))
    if (carouselList.length === 0) {
        return <></>
    } else {
        if (screenType == `mobile`) {
            return (
                <MobileDiv>
                    <MobileH2>Polecamy również...</MobileH2>
                    <div style={{ width: "100vw" }}>
                        <Swiper
                            loop={carouselList.length >= 2}
                            slidesPerView="auto"
                            centeredSlides
                            spaceBetween={5}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: true,
                            }}
                        >
                            {slidesToRender}
                        </Swiper>
                    </div>
                </MobileDiv>
            )
        } else {
            return (
                <DesktopDiv>
                    <DesktopH1>Polecamy również...</DesktopH1>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView="auto"
                        loop={
                            carouselList.length >= 4 ||
                            (window.innerWidth <= 1920 &&
                                carouselList.length === 3)
                        }
                        autoplay={{ delay: 5000, disableOnInteraction: true }}
                    >
                        {staticSlides}
                    </Swiper>
                </DesktopDiv>
            )
        }
    }
}
