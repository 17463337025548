import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { devices } from "src/theme/device"
import Box from "src/styled/Box"
import {
    deleteItem,
    incrementItemQty,
    decrementItemQty,
} from "src/redux/basket"
import Carousel from "src/modules/Carousel"
import { Container, LinkButton, Text, Span, Button } from "src/styled"
import { round } from "src/utils"
import { height } from "styled-system"
import { fetchCarousel } from "src/redux/carousel"
import { fetchPrices } from "src/redux/prices"
const Header = styled.h1`
    ${devices.mobile} {
        font-size: 18px;
        margin-left: 10px;
        margin-bottom: 15px;
    }
    font-size: 28px;
    text-align: left !important;
`
const Flex = styled.div`
    ${devices.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
`
const Table = styled.table`
    padding: 0;
    width: 100%;
    ${devices.mobile} {
        text-align: left;
    }
    border-collapse: collapse;
    font-size: 18px;
    ${devices.laptop} {
        font-size: 14px;
    }
    text-align: center;
    tr:first-child {
        text-align: left;
        border-top: none;
    }

    th {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
        white-space: nowrap;
    }

    th,
    td {
        padding: 15px 10px;
        border-spacing: 0;
    }

    tr,
    p {
        *:last-child {
            ${devices.tablet} {
                text-align: right;
            }
            text-align: center;
        }
    }

    tr {
        border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
    tr:nth-last-child(2) {
        border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-bottom: none;
    }
    tr:last-child {
        border: none;
    }
`

const TrashIcon = styled(FontAwesomeIcon)`
    transition: 0.2s ease-in-out;
    color: gray;
    :hover {
        color: #de2424;
    }
    cursor: pointer;
`

const PlusButton = styled(Button)`
    background-color: transparent;
    margin-right: 3px;
    font-size: 12px;
    border: solid grey 1px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
`
const MinusButton = styled(Button)`
    background-color: transparent;
    margin-left: 3px;
    font-size: 12px;
    border: solid grey 1px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: ${props =>
        props.disabled ? "solid lightgrey 1px" : "solid grey 1px"};
    pointer-events: ${props => (props.disabled ? "none" : "auto")};
`

const IncreamentIcon = ({ onIncrese }) => {
    return (
        <PlusButton onClick={onIncrese}>
            <FontAwesomeIcon
                icon={faPlus}
                style={{
                    width: "10px",
                    height: "10px",
                    textAlign: "center",
                    color: "black",
                }}
            />
        </PlusButton>
    )
}
const DecrementIcon = ({ onDecrease, quantity }) => {
    return (
        <MinusButton
            onClick={onDecrease}
            quantity={quantity}
            disabled={quantity === 1}
        >
            <FontAwesomeIcon
                icon={faMinus}
                style={{
                    width: "10px",
                    height: "10px",
                    textAlign: "center",
                    color: `${quantity === 1 ? "lightgrey" : "black"}`,
                }}
            />
        </MinusButton>
    )
}
export default function Basket({ location }) {
    useEffect(() => {
        dispatch(fetchPrices())
        dispatch(fetchCarousel())
    }, [])
    const { ZONE_A, ZONE_B } = useSelector(state => state.prices)
    const { totalPrice, items } = useSelector(state => state.basket)
    const { userData, isVerified, isAuthenticated } = useSelector(
        state => state.user
    )
    const { systemDisabled } = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const [isRepeated, setIsRepeated] = useState(false)
    const [canRepeat, setCanRepeat] = useState(true)
    useEffect(() => {
        if (location?.state?.hasOwnProperty("repeated")) {
            setIsRepeated(true)
        }
        if (location?.state?.hasOwnProperty("orderDate")) {
            setCanRepeat(
                location.state.orderDate > new Date("2023-10-24").getTime()
                    ? true
                    : false
            )
        }
        try {
            document.getElementById("basket").style.display = "none"
        } catch (err) {
            console.log(err)
        }
    }, [])

    function deleteBasketItem(id) {
        dispatch(deleteItem(id))
    }

    function increaseBasketItemQty(id) {
        dispatch(incrementItemQty(id))
    }

    function decreaseBasketItemQty(id) {
        dispatch(decrementItemQty(id))
    }

    return (
        <>
            <Carousel />
            <Box m={"auto"} width={["95%", "90%", "85%"]} py={5}>
                {!items || items.length === 0 ? (
                    <Header color="secondary">
                        {" "}
                        Brak produktów w koszyku.{" "}
                    </Header>
                ) : (
                    <Box m={"auto"} width={["95%", "90%", "85%"]} py={20}>
                        <Table>
                            <tr>
                                <Header
                                // fontSize={{ md: 'xxxl', _: 'xl' }}
                                // textAlign={{ md: 'left', _: 'center' }}
                                >
                                    {isRepeated
                                        ? "Sczegóły zamówienia"
                                        : "Podsumowanie"}
                                </Header>
                            </tr>
                            {items.map(item => (
                                <tr>
                                    <td style={{ width: "50%" }}>
                                        {" "}
                                        {item.name}{" "}
                                    </td>
                                    <td style={{ width: "30%" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexGrow: "1",
                                            }}
                                        >
                                            <DecrementIcon
                                                onDecrease={() =>
                                                    decreaseBasketItemQty(
                                                        item.id
                                                    )
                                                }
                                                quantity={item.quantity}
                                            />
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    width: "30px",
                                                    textAlign: "center",
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                {item.quantity}
                                            </span>
                                            <IncreamentIcon
                                                onIncrese={() =>
                                                    increaseBasketItemQty(
                                                        item.id
                                                    )
                                                }
                                            />
                                            <div></div>
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                            whiteSpace: "nowrap",
                                            width: "20%",
                                        }}
                                    >
                                        <span style={{ marginRight: "10px" }}>
                                            {round(item.quantity * item.price)}{" "}
                                            zł
                                        </span>
                                        <TrashIcon
                                            onClick={() =>
                                                deleteBasketItem(item.id)
                                            }
                                            icon={faTrash}
                                            size={"lg"}
                                        />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td
                                    colSpan={4}
                                    style={{
                                        textAlign: "right",
                                        fontSize: "22px",
                                        fontWeight: "400",
                                        borderBottom: "none",
                                    }}
                                >
                                    <span style={{ marginRight: "5px" }}>
                                        Razem
                                    </span>
                                    <span style={{ fontWeight: "700" }}>
                                        {totalPrice} zł
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{ textAlign: "left" }}>
                                    Koszt dostawy zostanie ustalony podczas
                                    rozmowy z naszym konsultantem (w zależności
                                    od lokalizacji {ZONE_A} zł lub {ZONE_B} zł).
                                    Do zamówienia doliczamy również koszt
                                    opakowania.
                                </td>
                            </tr>
                        </Table>
                        <Flex>
                            {!systemDisabled &&
                                canRepeat &&
                                (isAuthenticated ? isVerified : true) && (
                                    <LinkButton
                                        variant="primary"
                                        to={"/summary"}
                                        width={300}
                                    >
                                        {isRepeated ? "Powtórz" : "Dalej"}
                                    </LinkButton>
                                )}
                            <LinkButton
                                variant="primary-reverse"
                                to={"/"}
                                width={300}
                            >
                                Wstecz
                            </LinkButton>
                        </Flex>

                        {systemDisabled && (
                            <Flex justifyContent="flex-end" pr={10}>
                                <Text color="danger">
                                    W dniu dziejszym nie przyjmujemy zamówień.
                                </Text>
                            </Flex>
                        )}

                        {!isVerified && isAuthenticated && (
                            <Flex justifyContent="flex-end" pr={10}>
                                <Text color="danger">
                                    Potwierdź swój adres mailowy:{" "}
                                    {userData.email}
                                </Text>
                            </Flex>
                        )}
                    </Box>
                )}
            </Box>
        </>
    )
}
